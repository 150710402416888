import React from 'react'
import styled from 'styled-components/native'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: black;
  z-index: 10;
  flex-direction: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? 'column' : 'row'};
`

const SecondButton = styled.TouchableOpacity`
  min-height: auto;
  background-color: #f14c00;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  width: 100%;
`

const BlackButton = styled(SecondButton)`
  background-color: #000000;
`

const BtnText = styled.Text`
  color: white;
  font-weight: bold;
  text-align: center;
  font-size: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '16px' : '24px'};
  padding: 22px;
`

const Overlay = ({ openTablet = () => {}, openDoor = () => {} }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1200,
  })

  return (
    <Wrapper isTabletOrMobileDevice={isTabletOrMobileDevice}>
      <SecondButton
        onPress={openDoor}
        isTabletOrMobileDevice={isTabletOrMobileDevice}
      >
        <BtnText isTabletOrMobileDevice={isTabletOrMobileDevice}>
          VIEW BOTTLES
        </BtnText>
      </SecondButton>
      <BlackButton
        onPress={openTablet}
        isTabletOrMobileDevice={isTabletOrMobileDevice}
      >
        <BtnText isTabletOrMobileDevice={isTabletOrMobileDevice}>
          MIX DRINK
        </BtnText>
      </BlackButton>
    </Wrapper>
  )
}

export default Overlay

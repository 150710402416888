import React from 'react'
import styled from 'styled-components/native'
import { messages } from '../assets/messages'
import MixButton from '../tablet/components/MixButton'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.View`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.33);
`

const Content = styled.View`
  background-color: white;
  position: absolute;
  z-index: 100;
  bottom: 20%;
  width: 768px;
  max-width: 95%;
  padding: 12px;
  border-radius: 15px;
  text-align: center;
`

const InfoText = styled.Text`
  font-size: 24px;
  margin-bottom: 24px;
`

const ButtonText = styled.Text`
  font-weight: bold;
  color: white;
  font-size: 24px;
`

const ContBtn = styled(MixButton)`
  padding: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '6% 0' : '4% 0'};
`

const InfoMessage = ({ message, onContinue }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1200,
  })
  return (
    <Wrapper>
      <Content>
        <InfoText>{messages[message]['en']}</InfoText>
        <ContBtn
          isTabletOrMobileDevice={isTabletOrMobileDevice}
          onPress={onContinue}
        >
          <ButtonText>CONTINUE</ButtonText>
        </ContBtn>
      </Content>
    </Wrapper>
  )
}

export default InfoMessage

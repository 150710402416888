import React, { useState, useEffect } from 'react'
import styled from 'styled-components/native'
import { FontAwesome5 } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native'
import MixButton, { BackButton } from '../components/MixButton'
import { useMediaQuery } from 'react-responsive'

export const Page = styled.View`
  display: flex;
  flex: 1;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
`

const LoaderWrapper = styled.View`
  display: flex;
  flex: 1;
`

const Loader = styled.View`
  display: flex;
  flex: 1;
  border: 1px solid white;
  flex-direction: row;
`

const MixContainer = styled.View`
  display: flex;
  flex: 4;
  flex-direction: row;
  padding-bottom: 5%;
`

const MixRow = styled.View`
  display: flex;
  flex-direction: column;
  flex: 9;
`

const MixPart = styled.View`
  display: flex;
  flex: 3;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  border: 1px solid white;
`
const Spacer = styled.View`
  display: flex;
  flex: 1;
`

const StyledIcon = styled(FontAwesome5)``

const MixText = styled.Text`
  color: ${({ black }) => (black ? 'black' : 'white')};
`

const DrinkPart = styled.View`
  background-color: ${({ black }) => (black ? 'black' : 'white')};
  flex: ${({ flex }) => flex};
  display: flex;
  justify-content: center;
  align-items: center;
`

const MixBtnText = styled.Text`
  color: white;
  font-weight: bold;
  text-align: center;
`

const ButtonRow = styled.View`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`

const Mix = ({ setCurrentTab, mix }) => {
  const [drinks, setDrinks] = useState([])
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1200,
  })

  const addDrink = (drink) => {
    if (drinks.filter((el) => el.name === drink.name).length === 0) {
      setDrinks([
        ...drinks,
        { name: drink.name, parts: 1, position: drink.position },
      ])
    } else {
      let curr = drinks
      for (let i = 0; i < curr.length; i++) {
        if (curr[i].name === drink.name) {
          curr[i].parts = curr[i].parts + 1
          setDrinks([...curr])
          break
        }
      }
    }
  }

  const removeDrink = (drink) => {
    let curr = drinks
    for (let i = 0; i < curr.length; i++) {
      if (curr[i].name === drink.name) {
        curr[i].parts = curr[i].parts - 1
        if (curr[i].parts < 1) {
          setDrinks(drinks.filter((el) => el.name != drink.name))
        } else {
          setDrinks([...curr])
        }
        break
      }
    }
  }

  return (
    <Page>
      <Spacer />
      <ButtonRow>
        <BackButton
          onPress={() => {
            setCurrentTab('home')
          }}
        >
          <MixBtnText>BACK</MixBtnText>
        </BackButton>
        {drinks.length > 0 ? (
          <MixButton
            onPress={() => {
              mix(drinks)
            }}
          >
            <MixBtnText>MIX</MixBtnText>
          </MixButton>
        ) : (
          <Spacer />
        )}
      </ButtonRow>
      <Spacer />
      <LoaderWrapper>
        <Loader>
          {drinks.map((drink, d) => (
            <DrinkPart
              key={`${drink.parts}-${d}`}
              flex={drink.parts}
              black={d % 2}
            >
              <MixText black={!(d % 2)}>{drink.name}</MixText>
            </DrinkPart>
          ))}
        </Loader>
      </LoaderWrapper>
      <Spacer />
      <MixContainer>
        <MixRow>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink1', position: 1 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 1</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink1', position: 1 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink2', position: 2 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 2</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink2', position: 2 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink3', position: 3 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 3</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink3', position: 3 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
        </MixRow>
        <Spacer />
        <MixRow>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink4', position: 4 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 4</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink4', position: 4 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink5', position: 5 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 5</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink5', position: 5 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
          <Spacer />
          <MixPart>
            <TouchableOpacity
              onPress={() => removeDrink({ name: 'drink6', position: 6 })}
            >
              <StyledIcon
                name="minus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
            <MixText>Drink 6</MixText>
            <TouchableOpacity
              onPress={() => addDrink({ name: 'drink6', position: 6 })}
            >
              <StyledIcon
                name="plus"
                size={isTabletOrMobileDevice ? 16 : 32}
                color="white"
              />
            </TouchableOpacity>
          </MixPart>
        </MixRow>
      </MixContainer>
    </Page>
  )
}

export default Mix

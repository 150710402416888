import { ExpoWebGLRenderingContext, GLView } from 'expo-gl'
import { Renderer, THREE } from 'expo-three'
import React, { useEffect, useState } from 'react'
import {
  AmbientLight,
  GridHelper,
  PerspectiveCamera,
  Scene,
  SpotLight,
} from 'three'
import { ColladaLoader } from './loader/ColladaLoader'
import OrbitControlsView from 'expo-three-orbit-controls'
import Tablet from './tablet/tablet'
import styled from 'styled-components/native'
import Overlay from './components/overlay'
import InfoMessage from './components/InfoMessage'
import { messages } from './assets/messages'

const Wrapper = styled.View`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

const degToRad = (deg) => {
  return (Math.PI / 180) * deg
}

const drinkDeg = 48
let drinks = []

let goalPosition = { x: -50, y: 80, z: 100 }
let animationMode = false
let doorOpen = false
export default function App() {
  let timeout

  const [camera, setCamera] = useState(null)
  const [renderer, setRenderer] = useState(null)
  const [raycaster, setRaycaster] = useState(null)
  const [mouse, setMouse] = useState(null)
  const [scene, setScene] = useState(null)
  const [tabletVisible, setTabletVisible] = useState(false)
  const [message, setMessage] = useState('welcome')
  const [infoVisible, setInfoVisible] = useState(true)

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('resize', onWindowResize, false)
    document.addEventListener('mousedown', onMouseClick, false)
    // Clear the animation loop when the component unmounts
    return () => {
      clearTimeout(timeout)
      window.removeEventListener('mousemove', onMouseMove, false)
      window.removeEventListener('resize', onWindowResize, false)
      document.removeEventListener('mousedown', onMouseClick, false)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, false)
    window.addEventListener('resize', onWindowResize, false)
    document.addEventListener('mousedown', onMouseClick, false)
    console.log(raycaster)
  }, [raycaster, scene, mouse])

  const onWindowResize = () => {
    if (camera && renderer) {
      camera.aspect = window.innerWidth / window.innerHeight
      camera.updateProjectionMatrix()

      renderer.setSize(window.innerWidth, window.innerHeight)
      setRenderer(renderer)
      setCamera(camera)
    }
  }

  const openTablet = () => {
    setTimeout(() => setTabletVisible(true), 800)
    goalPosition = { x: 25, y: 60, z: 50 }
    animationMode = true
  }
  const openDoor = () => {
    setTimeout(() => {
      doorOpen = true
    }, 400)
    goalPosition = { x: -40, y: 80, z: -80 }
    animationMode = true
  }
  const onMouseClick = (event) => {
    event.preventDefault()
    if (raycaster && scene) {
      const intersects = raycaster.intersectObjects(scene.children)

      for (var i = 0; i < intersects.length; i++) {
        if (intersects[i].object.name === 'tablet-hitbox') {
          openTablet()
        }
      }
    }
  }

  const onMouseMove = (event) => {
    event.preventDefault()
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components

    if (mouse) {
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1
      setMouse(mouse)
    }
  }

  const mix = (thedrinks) => {
    drinks = thedrinks
    goalPosition = { x: -35, y: 90, z: 75 }
    animationMode = true
    setTabletVisible(false)
    setMessage('glass')
    setInfoVisible(true)
  }

  const onNextMessage = () => {
    const nextMsg = messages[message].next
    if (nextMsg === 'mix') {
      openTablet()
      setInfoVisible(false)
    } else if (nextMsg === 'finished') {
      goalPosition = { x: 20, y: 23, z: 55 }
      animationMode = true
      setMessage(nextMsg)
      setTabletVisible(false)
    } else {
      if (nextMsg === '') {
        setInfoVisible(false)
      } else {
        setInfoVisible(true)
      }
      setTabletVisible(false)
      setMessage(nextMsg)
    }
  }

  return (
    <>
      <Overlay openTablet={openTablet} openDoor={openDoor} />
      {tabletVisible && (
        <Tablet onClose={() => setTabletVisible(false)} mix={mix} />
      )}
      {infoVisible && (
        <InfoMessage message={message} onContinue={onNextMessage} />
      )}
      <OrbitControlsView style={{ flex: 1 }} camera={camera}>
        <GLView
          key="d"
          style={{ flex: 1 }}
          onContextCreate={async (gl) => {
            const {
              drawingBufferWidth: width,
              drawingBufferHeight: height,
            } = gl
            const sceneColor = 0xffffff

            const urls = [
              require('./assets/3d/envmap/cubemap/px.png'),
              require('./assets/3d/envmap/cubemap/nx.png'),
              require('./assets/3d/envmap/cubemap/py.png'),
              require('./assets/3d/envmap/cubemap/ny.png'),
              require('./assets/3d/envmap/cubemap/pz.png'),
              require('./assets/3d/envmap/cubemap/nz.png'),
            ]
            const reflectionCube = new THREE.CubeTextureLoader().load(urls)

            // Create a WebGLRenderer without a DOM element
            const renderer = new Renderer({ gl })
            renderer.setSize(width, height)
            renderer.setClearColor(sceneColor)
            setRenderer(renderer)

            const camera = new PerspectiveCamera(
              70,
              width / height,
              0.01,
              10000
            )
            camera.position.set(goalPosition.x, goalPosition.y, goalPosition.z)
            setCamera(camera)

            const raycaster = new THREE.Raycaster()
            setRaycaster(raycaster)
            const mouse = new THREE.Vector2()
            setMouse(mouse)

            const scene = new Scene()
            scene.background = reflectionCube

            const loader = new THREE.TextureLoader()
            let bottleGroup = new THREE.Group()

            const tabletMaterial = new THREE.MeshLambertMaterial({
              map: loader.load(require('./assets/3d/tablet.jpg')),
            })
            const tabletGeometry = new THREE.PlaneGeometry(
              800 * 0.0202,
              480 * 0.0202
            )
            const tabletMesh = new THREE.Mesh(tabletGeometry, tabletMaterial)
            tabletMesh.position.set(7.75, 40.25, 26)
            tabletMesh.rotateX(degToRad(-30.5))
            scene.add(tabletMesh)

            const triangleShape = new THREE.Shape()
            triangleShape.moveTo(0, 0)
            triangleShape.lineTo(38.7, 0)
            triangleShape.lineTo(38.7, 33)
            triangleShape.lineTo(0, 0)
            const triangleGeometry = new THREE.ExtrudeGeometry(triangleShape, {
              depth: 1,
              bevelSize: 0,
              bevelSegments: 1,
              bevelThickness: 0,
            })
            const triangelMaterial = new THREE.MeshLambertMaterial({
              color: 0xffffff,
              emissive: 0x1c0e08,
              transparent: true,
              opacity: 0.15,
            })
            const triangelMesh = new THREE.Mesh(
              triangleGeometry,
              triangelMaterial
            )
            triangelMesh.position.set(19.5, 72, 19)
            triangelMesh.rotateZ(degToRad(180))
            scene.add(triangelMesh)

            loader.load(
              require('./assets/3d/envmap/plywood_diff_1k.jpg'),
              (texture) => {
                const groundMaterial = new THREE.MeshLambertMaterial({
                  map: texture,
                })
                const mesh = new THREE.Mesh(
                  new THREE.PlaneBufferGeometry(200, 200),
                  groundMaterial
                )
                mesh.position.y = 0
                mesh.rotation.x = -Math.PI / 2
                scene.add(mesh)
              }
            )

            const ambientLight = new AmbientLight(0x111111)
            scene.add(ambientLight)

            const spotLight = new SpotLight(0xffffff, 1)
            spotLight.position.set(-70, 170, 150)
            spotLight.lookAt(scene.position)
            scene.add(spotLight)
            const spotLight2 = new SpotLight(0xffffff, 1)
            spotLight2.position.set(70, 70, -150)
            spotLight2.lookAt(scene.position)
            scene.add(spotLight2)

            const loaderCollada = new ColladaLoader()

            const glassMaterial = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              emissive: 0x666666,
              metalness: 1,
              roughness: 0,
              envMap: reflectionCube,
            })
            loaderCollada.load(
              require('./assets/3d/glass/glass.dae'),
              (collada) => {
                var avatar = collada.scene
                avatar.position.x = 0
                avatar.position.y = 10.5
                avatar.position.z = 13
                avatar.children[0].material = glassMaterial
                //avatar.scale.set(0.1, 0.1, 0.1)
                scene.add(avatar)
                setScene(scene)
              }
            )
            const limixMaterial = new THREE.MeshStandardMaterial({
              color: 0x373839,
              emissive: 0x000000,
              roughness: 0.2,
              metalness: 0,
            })
            loaderCollada.load(
              require('./assets/3d/limix_without.dae'),
              (collada) => {
                var avatar = collada.scene
                avatar.position.x = -8
                avatar.position.y = 0
                avatar.position.z = 3
                avatar.children[0].children[1].material = limixMaterial
                scene.add(avatar)
                setScene(scene)
              }
            )
            let door
            const doorGeometry = new THREE.BoxGeometry(37, 38, 2)
            door = new THREE.Mesh(doorGeometry, limixMaterial)
            door.position.set(-0.19, 52.15, -17.9)
            scene.add(door)
            let drehteller
            loaderCollada.load(
              require('./assets/3d/drehteller.dae'),
              (collada) => {
                var avatar = collada.scene
                avatar.position.x = 0
                avatar.position.y = 0
                avatar.scale.set(0.999, 0.999, 0.999)
                avatar.children[0].material = limixMaterial
                avatar.position.z = 0
                drehteller = avatar
                bottleGroup.position.z = 0.75
                bottleGroup.rotation.y = degToRad(drinkDeg)
                bottleGroup.add(drehteller)
                setScene(scene)
              }
            )
            const bottleMaterial = new THREE.MeshStandardMaterial({
              color: 0x262626,
              emissive: 0x000000,
              roughness: 0.2,
              metalness: 0.5,
            })
            let bottles = []
            loaderCollada.load(
              require('./assets/3d/glass/bottle.dae'),
              (collada) => {
                const bottlePositions = [
                  {},
                  {
                    x: 1.75,
                    z: 11,
                  },
                  {
                    x: -8.5,
                    z: 7,
                  },
                  {
                    x: 10,
                    z: 3.75,
                  },
                  {
                    x: -10,
                    z: -3.5,
                  },
                  {
                    x: 8.25,
                    z: -6.75,
                  },
                  {
                    x: -1.5,
                    z: -10.5,
                  },
                ]
                let avatar = collada.scene
                bottles.push(avatar)
                for (let i = 1; i < 7; i++) {
                  bottles.push(bottles[0].clone())
                  bottles[i].position.x = bottlePositions[i].x
                  bottles[i].position.y = 33
                  bottles[i].position.z = bottlePositions[i].z
                  bottles[i].children[0].material = bottleMaterial
                  bottleGroup.add(bottles[i])
                }
                scene.add(bottleGroup)
                setScene(scene)
              }
            )

            const tasseMaterial = new THREE.MeshStandardMaterial({
              color: 0xffffff,
              emissive: 0x999999,
              roughness: 0.4,
              metalness: 1,
            })
            loaderCollada.load(
              require('./assets/3d/tropftasse.dae'),
              (collada) => {
                var avatar = collada.scene
                avatar.position.x = -8
                avatar.position.y = 0
                avatar.position.z = 3
                avatar.children[0].children[1].material = tasseMaterial
                avatar.children[0].children[0].material = tasseMaterial
                scene.add(avatar)
                setScene(scene)
              }
            )

            const hitbox = new THREE.Mesh(
              new THREE.BoxGeometry(20, 5, 11),
              new THREE.MeshBasicMaterial({ color: 0x00ff00 })
            )
            hitbox.name = 'tablet-hitbox'
            hitbox.position.set(8, 40, 24)
            hitbox.rotateX(45)
            hitbox.visible = false
            scene.add(hitbox)
            setScene(scene)

            const update = () => {
              camera.lookAt(0, 20, 0)

              if (bottleGroup) {
                if (drinks.length > 0) {
                  for (let i = 0; i < 2 && drinks[0]; i++) {
                    if (
                      bottleGroup.rotation.y.toFixed(2) >
                      degToRad(drinks[0].position * 48).toFixed(2)
                    ) {
                      bottleGroup.rotation.y -= 0.01
                    } else if (
                      bottleGroup.rotation.y.toFixed(2) <
                      degToRad(drinks[0].position * 48).toFixed(2)
                    ) {
                      bottleGroup.rotation.y += 0.01
                    } else {
                      drinks.shift()
                    }
                  }
                }
              }
              if (door) {
                if (doorOpen && door.rotation.y !== degToRad(-125)) {
                  door.rotation.y = degToRad(-125)
                  door.position.z = -35
                  door.position.x = 28
                }
              }

              if (
                (camera.position.x - goalPosition.x > 1 ||
                  camera.position.y - goalPosition.y > 1 ||
                  camera.position.z - goalPosition.z > 1) &&
                animationMode
              ) {
                camera.position.set(
                  camera.position.x + (goalPosition.x - camera.position.x) / 20,
                  camera.position.y + (goalPosition.y - camera.position.y) / 20,
                  camera.position.z + (goalPosition.z - camera.position.z) / 20
                )
              } else {
                animationMode = false
              }
              raycaster.setFromCamera(mouse, camera)
              setRaycaster(raycaster)

              setCamera(camera)
            }

            // Setup an animation loop
            const render = () => {
              timeout = requestAnimationFrame(render)
              update()
              renderer.render(scene, camera)
              gl.endFrameEXP()
            }
            render()
          }}
        />
      </OrbitControlsView>
    </>
  )
}

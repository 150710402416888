import styled from 'styled-components/native'
import React, { useState, useEffect } from 'react'
import { LinearGradient } from 'expo-linear-gradient'
import Mix from './pages/Mix'
import Standard from './pages/Standard'
import '@expo/match-media'
import { useMediaQuery } from 'react-responsive'

const Wrapper = styled.View`
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 50;
  justify-content: center;
  align-items: center;
`
const TouchableWithoutFeedback = styled.TouchableWithoutFeedback``
const Close = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const TabletWrapper = styled(LinearGradient)`
  width: 90vw;
  max-width: 800px;
  height: 54vw;
  max-height: 480px;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const LogoWrapper = styled.View`
  display: flex;
  flex: 1;
  padding: 36px;
  width: 100%;
`
const Logo = styled.Image`
  width: 100%;
  height: 100%;
`

const Choose = styled.View`
  display: flex;
  flex: 2;
  flex-direction: row;
  width: 100%;
`

const ChooseBtn = styled.TouchableOpacity`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: ${({ border }) => (border ? 1 : 0)}px;
  border-left-width: ${({ border2 }) => (border2 ? 1 : 0)}px;
  flex: 1;
`

const ChooseText = styled.Text`
  color: white;
  /* MEDIA QUERY */
  font-size: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '18px' : '36px'};
  text-align: center;
`

const Tablet = ({ onClose = () => {}, mix }) => {
  const [currentTab, setCurrentTab] = useState('')
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1200,
  })

  return (
    <Wrapper>
      <TouchableWithoutFeedback onPress={() => onClose()}>
        <Close />
      </TouchableWithoutFeedback>
      <TabletWrapper colors={['#4f4f4f', '#1b2625']}>
        {currentTab === 'mix' ? (
          <Mix setCurrentTab={setCurrentTab} mix={mix} />
        ) : currentTab === 'standard' ? (
          <Standard setCurrentTab={setCurrentTab} mix={mix} />
        ) : (
          <>
            <LogoWrapper>
              <Logo
                source={require('../assets/weiss-orange.png')}
                resizeMode="contain"
              />
            </LogoWrapper>
            <Choose>
              <ChooseBtn border onPress={() => setCurrentTab('standard')}>
                <ChooseText isTabletOrMobileDevice={isTabletOrMobileDevice}>
                  STANDARD DRINKS
                </ChooseText>
              </ChooseBtn>
              <ChooseBtn border2 onPress={() => setCurrentTab('mix')}>
                <ChooseText isTabletOrMobileDevice={isTabletOrMobileDevice}>
                  MIX
                </ChooseText>
              </ChooseBtn>
            </Choose>
          </>
        )}
      </TabletWrapper>
    </Wrapper>
  )
}

export default Tablet

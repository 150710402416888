import styled from 'styled-components/native'

const MixButton = styled.TouchableOpacity`
  padding: 3%;
  background-color: #f14c00;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
`
export const BackButton = styled(MixButton)`
  background-color: #000000;
`

export default MixButton

export const SecondButton = styled.TouchableOpacity`
  padding: 3%;
  background-color: transparent;
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
  border-color: #f14c00;
  border-width: 3px;
  border-radius: 15px;
`

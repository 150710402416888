import React, { useState, useEffect } from 'react'
import styled from 'styled-components/native'
import { FontAwesome5 } from '@expo/vector-icons'
import { TouchableOpacity } from 'react-native'
import MixButton, { BackButton } from '../components/MixButton'
import { useMediaQuery } from 'react-responsive'

export const Page = styled.View`
  display: flex;
  flex: 1;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
`

const Spacer = styled.View`
  display: flex;
  flex: 1;
`

const Headline = styled.Text`
  font-size: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '18px' : '36px'};
  width: 100%;
  text-align: center;
  color: white;
`

const Row = styled.View`
  display: flex;
  flex-direction: row;
  flex: 8;
`

const StandardDrink = styled.TouchableOpacity`
  display: flex;
  margin: 5%;
  flex: 1;
  border-width: 1px;
  border-color: #ff5100;
  border-style: solid;
  flex-direction: column;
`

const StandardDrinkTitle = styled.Text`
  font-size: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '14px' : '24px'};
  color: white;
  width: 100%;
  text-align: center;
  margin: 14px 0;
`

const Ingridient = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;
  width: 100%;
  max-width: 100%;
`

const IngridientText = styled.Text`
  color: white;
  margin: 0 5%;
  font-size: ${({ isTabletOrMobileDevice }) =>
    isTabletOrMobileDevice ? '10px' : '16px'};
`

const standards = [
  {
    name: 'Gin-Tonic',
    drinks: [
      {
        name: 'Gin',
        percent: 20,
      },
      {
        name: 'Tonic Water',
        percent: 80,
      },
    ],
  },
  {
    name: 'Whisky-Cola',
    drinks: [
      {
        name: 'Whisky',
        percent: 30,
      },
      {
        name: 'Cola',
        percent: 70,
      },
    ],
  },
  {
    name: 'LiMix Hirsch',
    drinks: [
      {
        name: 'Jägermeister',
        percent: 40,
      },
      {
        name: 'Milk',
        percent: 40,
      },
      {
        name: 'Bananas',
        percent: 20,
      },
    ],
  },
]

const Standard = ({ setCurrentTab, mix }) => {
  const isTabletOrMobileDevice = useMediaQuery({
    maxDeviceWidth: 1200,
  })

  return (
    <Page>
      <Spacer />
      <Headline isTabletOrMobileDevice={isTabletOrMobileDevice}>
        STANDARD DRINKS
      </Headline>
      <Spacer />
      <Row>
        {standards.map((el, index) => (
          <StandardDrink
            kex={index}
            onPress={() => {
              mix([
                el.drinks.map((drink, i) => {
                  return {
                    name: drink.name,
                    position: i + 1,
                  }
                }),
              ])
            }}
          >
            <StandardDrinkTitle isTabletOrMobileDevice={isTabletOrMobileDevice}>
              {el.name}
            </StandardDrinkTitle>
            {el.drinks.map((ing, index) => (
              <Ingridient key={index}>
                <IngridientText isTabletOrMobileDevice={isTabletOrMobileDevice}>
                  {ing.name}
                </IngridientText>
                <IngridientText isTabletOrMobileDevice={isTabletOrMobileDevice}>
                  {ing.percent}%
                </IngridientText>
              </Ingridient>
            ))}
          </StandardDrink>
        ))}
      </Row>
    </Page>
  )
}

export default Standard

export const messages = {
  welcome: {
    de: 'Lieblingsgetränk wählen!',
    en:
      "It's 3am, you want something to drink but unfortunately there is no barkeeper!",
    next: 'intro',
  },
  intro: {
    de: '',
    en: 'Thanks god the LiMix exists.. choose your favorite drink!',
    next: 'mix',
  },
  glass: {
    de: 'Glas einstellen',
    en:
      'Now please insert your drinking glass, pay cashless and wait a few seconds!',
    next: 'finished',
  },
  finished: {
    de: 'Bargeldlos bezahlen und Getränk genießen!',
    en: 'Now LiMix has mixed your favorite drink and you can enjoy it!',
    next: '',
  },
}
